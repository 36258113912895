import { startOfDay, isAfter, addDays, isWithinInterval } from 'date-fns';

interface PriceItem {
  date: string;
  priceItem: {
    onwardPrice: {
      value: number;
      display: string;
    };
  };
}

interface FlightItem {
  flight: {
    price: {
      value: number;
    };
  };
}

interface ComparativePrice {
  date: string;
  price: string;
  rawPrice: number;
}

export const getComparativePrices = (
  flightPrices: PriceItem[],
  sortedFlightItems: FlightItem[],
  selectedDate: string
): ComparativePrice[] => {
  if (!flightPrices?.length || !sortedFlightItems?.length) return [];

  const tomorrow = startOfDay(addDays(new Date(), 1));
  const selectedDateObj = startOfDay(new Date(selectedDate));
  
  // Create date range (5 days before and after selected date)
  const rangeStart = startOfDay(addDays(selectedDateObj, -5));
  const rangeEnd = startOfDay(addDays(selectedDateObj, 5));

  // Get the lowest price from current results
  const lowestPrice = Math.min(
    ...sortedFlightItems.map(item => item.flight.price.value)
  );

  return flightPrices
    ?.filter(priceItem => {
      const price = priceItem?.priceItem?.onwardPrice?.value;
      const priceDate = startOfDay(new Date(priceItem.date));
      
      // Check if date is within range and after tomorrow
      const isInRange = isWithinInterval(priceDate, { start: rangeStart, end: rangeEnd });
      const isFuture = isAfter(priceDate, tomorrow);
      const isNotSelectedDate = priceDate.getTime() !== selectedDateObj.getTime();
      
      return price < lowestPrice && isInRange && isFuture && isNotSelectedDate;
    })
    ?.slice(0, 4) // Take up to 4 items
    ?.map(priceItem => ({
      date: priceItem?.date,
      price: priceItem?.priceItem?.onwardPrice?.display,
      rawPrice: priceItem?.priceItem?.onwardPrice?.value
    }))
    ?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
};
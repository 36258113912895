import { Box, Container, Skeleton, Typography } from '@mui/material';
import { OAButton, OACheckbox, OAImage } from '../../components';
import { useDispatch, useSelector } from '../../store';
import { getUserInputState } from '../../store/slices/userInputSlice';
import { getFlightOrderState } from '../../store/slices/flightOrderSlice';
import { setMessage } from '../../store/slices/snackbarSlice';
import { useGetTravellersQuery } from '../../services/travellersApi';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';

interface ListProps {
  selectedTravelers: any;
  setSelectedTravelers: any;
  toggleDrawer: () => void;
  setSelectedTraveller: (traveller: any) => void;
  setFieldErrors: any;
}
const List = ({
  selectedTravelers,
  setSelectedTravelers,
  toggleDrawer,
  setSelectedTraveller,
  setFieldErrors,
}: ListProps) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const { travelDetail } = useSelector(getUserInputState);
  const { data: travellers, isLoading: loading } = useGetTravellersQuery({});
  const { flightData } = useSelector(getFlightOrderState);
  const [startTime, setStartTime] = useState(Date.now());
  const [showAllTravellers, setShowAllTravellers] = useState<boolean>(false);

  const totalCount =
    (flightData?.travellerCount?.adult +
      flightData?.travellerCount?.child +
      flightData?.travellerCount?.infant)
    || (travelDetail?.travellerCount?.adult + travelDetail?.travellerCount?.child + travelDetail?.travellerCount?.infant)

  const getCurrentSelectionCounts = () => {
    return selectedTravelers?.reduce(
      (acc: any, id: string) => {
        const found = travellers?.data?.find((t: any) => t?.id === id);
        if (found) {
          acc[found?.type] = (acc[found?.type] || 0) + 1;
        }
        return acc;
      },
      { ADULT: 0, CHILD: 0, INFANT: 0 }
    );
  };

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleToggleTravellerSelection = useCallback(
    (traveller: any) => {
      const currentTraveller = travellers?.data?.find((t: any) => t?.id === traveller?.id);
      if (!currentTraveller) return;

      if (
        flightData?.flightSearchInfo?.fareGroup === 'STUDENT' &&
        (currentTraveller?.type === 'CHILD' || currentTraveller?.type === 'INFANT')
      ) {
        dispatch(setMessage('Student fares are available for adult passengers only.'));
        return;
      }

      let errors = {};

      if (flightData?.flightSearchInfo?.fareGroup === 'STUDENT' && !currentTraveller?.studentId) {
        errors = {
          ...errors,
          studentId: 'Student ID is required',
        };
      }

      if (flightData?.international && !currentTraveller?.passport) {
        errors = {
          ...errors,
          passportNumber: 'Passport number is required',
          passportExpiry: 'Passport expiry date is required',
          passportIssueDate: 'Passport issue date is required',
        };
      }

      if (Object.keys(errors).length > 0) {
        setSelectedTraveller(traveller);
        setFieldErrors(errors);
        toggleDrawer();
      } else {
        const currentSelectionCounts = getCurrentSelectionCounts();

        const allowedCounts: any = {
          ADULT: flightData?.travellerCount?.adult || travelDetail?.travellerCount?.adult,
          CHILD: flightData?.travellerCount?.child || travelDetail?.travellerCount?.child,
          INFANT: flightData?.travellerCount?.infant || travelDetail?.travellerCount?.infant,
        };

        if (selectedTravelers?.includes(traveller?.id)) {
          // if already selected, allow deselection
          setSelectedTravelers(selectedTravelers?.filter((id: any) => id !== traveller?.id));
        } else {
          // checking if adding this traveler would exceed the allowed count for their type
          if (
            currentSelectionCounts[currentTraveller?.type] < allowedCounts[currentTraveller?.type]
          ) {
            setSelectedTravelers([...selectedTravelers, traveller?.id]);
          } else {
            dispatch(
              setMessage(`Cannot select more ${currentTraveller.type.toLowerCase()}s than allowed.`)
            );
          }
        }
      }
    },
    [
      selectedTravelers,
      setSelectedTravelers,
      toggleDrawer,
      setSelectedTraveller,
      travellers,
      flightData,
      dispatch,
    ]
  );

  const handleAddTraveller = useCallback(() => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Confirm',
        ctaAction: 'Addtraveller',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    if (totalCount !== selectedTravelers?.length && totalCount > selectedTravelers?.length) {
      toggleDrawer();
    } else {
      dispatch(setMessage('You have already selected all the travellers'));
    }
  }, [totalCount, selectedTravelers, toggleDrawer, dispatch]);

  const calculateAgeDisplay = useCallback((traveller: any) => {
    if (traveller?.age > 0) {
      return `${traveller?.age} yrs`;
    } else {
      const birthDate = new Date(traveller?.dateOfBirth);
      const today = new Date();
      let months = (today.getFullYear() - birthDate.getFullYear()) * 12;
      months -= birthDate.getMonth();
      months += today.getMonth();
      return `${months} mth${months !== 1 ? 's' : ''}`;
    }
  }, []);

  const sortedTravellers = useMemo(() => {
    if (!travellers?.data) return [];
    
    const now = new Date();
    const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000);
    
    return [...travellers?.data]?.sort((a, b) => {
      const aDate = new Date(a?.updatedAt);
      const bDate = new Date(b?.updatedAt);
      
      // Check if either date is within the last 5 minutes
      const aIsRecent = aDate <= now && aDate > fiveMinutesAgo;
      const bIsRecent = bDate <= now && bDate > fiveMinutesAgo;
      
      // If one is recent and other isn't, prioritize the recent one
      if (aIsRecent && !bIsRecent) return -1;
      if (!bIsRecent && aIsRecent) return 1;
      
      // If neither is recent or both are recent, maintain original order
      return 0;
    });
  }, [travellers?.data]);

  const displayedTravellers = useMemo(() => {
    return showAllTravellers ? sortedTravellers : sortedTravellers.slice(0, 4);
  }, [sortedTravellers, showAllTravellers]);

  return (
    <>
      <Container sx={{ mt: '16px' }}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Box display="flex" alignItems="end" mb="4px">
              <Typography variant="body1" sx={{ mr: '6px' }} fontWeight={500}>
                Travellers
              </Typography>
            </Box>
            <Box mb='12px'>
              <Typography variant="body3" sx={{ color: 'grey.800' }}>
                ({travellers?.data?.length > 0 ? 'Select' : 'Add'}:{' '}
                {travelDetail?.travellerCount?.adult
                  ? `${travelDetail.travellerCount.adult} Adult${travelDetail.travellerCount.adult > 1 ? 's' : ''
                  }`
                  : ''}
                {travelDetail?.travellerCount?.child
                  ? ` • ${travelDetail.travellerCount.child} Child${travelDetail.travellerCount.child > 1 ? 'ren' : ''
                  }`
                  : ''}
                {travelDetail?.travellerCount?.infant
                  ? ` • ${travelDetail.travellerCount.infant} Infant${travelDetail.travellerCount.infant > 1 ? 's' : ''
                  }`
                  : ''}
                )
              </Typography>
            </Box>
          </Box>
          <Box>
            <OAButton onClick={handleAddTraveller} size="small" variant="outlined" color="secondary" sx={{ borderRadius: '8px', py: '5px' }}>
              <OAImage src="add-black.svg" folder="icons" alt="add" sx={{ mr: '5px' }} />
              Add
            </OAButton>
          </Box>
        </Box>
        {travellers?.data?.length > 0 ? (
          <Box bgcolor='#F4F6F5' p='10px' borderRadius='10px'>
            {displayedTravellers.map((traveller: any, index: number) => (
              <Box key={traveller?.id} mb='16px'>
                <OACheckbox
                  label={`${traveller?.firstName} ${traveller?.lastName}, ${calculateAgeDisplay(traveller)}`}
                  checked={selectedTravelers?.includes(traveller?.id)}
                  onChange={() => handleToggleTravellerSelection(traveller)}
                  onEdit={() => {
                    setSelectedTraveller(traveller);
                    toggleDrawer();
                  }}
                  labelStyle={{
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                />
              </Box>
            ))}
            {travellers?.data?.length > 4 && (
              <Box
                mt={2}
                onClick={() => setShowAllTravellers(!showAllTravellers)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  borderRadius: '4px'
                }}
              >
                <Typography variant="body3" fontWeight={600} color="#009E82">
                  {showAllTravellers ? 'Show less' : 'Show all travellers'}
                </Typography>
                <OAImage src={showAllTravellers ? 'drop-up.svg' : 'drop-down.svg'} folder="icons" alt="arrow down" />
              </Box>
            )}
          </Box>
        ) : (
          <Box mt="16px">
            {loading && (
              <>
                {' '}
                <Skeleton sx={{ mb: '16px' }} />
                <Skeleton sx={{ mb: '16px' }} />
                <Skeleton sx={{ mb: '16px' }} />
                <Skeleton />
              </>
            )}
          </Box>
        )}
      </Container>
    </>
  );
};

export default List;

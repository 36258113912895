import {
  Box,
  Card,
  Container,
  Divider,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { OAImage, OASwipeableDrawer } from '../../../components';
import RefundBreakup from './RefundBreakup';
import { useDispatch, useSelector } from '../../../store';
import { getCancellationState } from '../../../store/slices/cancellationSlice';
import { useState } from 'react';
import { displayDateTime, formatPaymentMethod, formatToINR, getFormatedDate } from '../../../utils';
import { setMessage } from '../../../store/slices/snackbarSlice';

const StatusCircle = ({ color }: { color: string }) => (
  <Box
    sx={{
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: color,
    }}
  />
);

const DynamicIcon = ({ status }: any) => {
  switch (status) {
    case 'COMPLETED':
      return <StatusCircle color="#49A27A" />;
    case 'REFUND_INITIATED':
    case 'REFUND_PROCESSING':
    case 'REFUND_SUCCESS':
      return <StatusCircle color="#49A27A" />;
    case 'REFUND_FAILED':
      return <StatusCircle color="#F1BF4D" />;
    default:
      return <OAImage src="clock.svg" folder="icons" alt="pending" />;
  }
};

const RefundDetails = ({ data }: any) => {
  const dispatch = useDispatch();
  const { cancellationData } = useSelector(getCancellationState);
  const [isRefundBreakupOpen, setIsRefundBreakupOpen] = useState<boolean>(false);

  const paymentSummary = data?.data?.paymentSummary;

  const toggleRefundBreakupDrawer = () => {
    setIsRefundBreakupOpen(!isRefundBreakupOpen);
  };

  const refundLogs = data?.data?.paymentSummary?.logs?.filter((log: any) => log?.type === 'REFUND');
  const isRefundInitiated = refundLogs?.some((log: any) => log?.status === 'REFUND_INITIATED');
  const cancellationRequestLog = data?.data?.logs?.filter(
    (log: any) => log?.status === 'CANCELLED'
  );
  const paymentSuccessfulLog = data?.data?.orderAmount?.paymentSummary?.logs?.filter(
    (log: any) => log?.status === 'PAYMENT_SUCCESSFUL'
  );
  const bookingFailedLog = data?.data?.logs?.filter((log: any) => log?.status === 'BOOKING_FAILED');
  const isRefundFailed = refundLogs?.some((log: any) => log?.status === 'REFUND_FAILED');

  const refundSteps = [
    { key: 'REFUND_INITIATED', label: 'Refund initiated' },
    ...(!isRefundFailed ? [{ key: 'REFUND_PROCESSING', label: 'Refund processing' }] : []),
    ...(isRefundFailed ? [{ key: 'REFUND_FAILED', label: 'Refund pending' }] : []),
    { key: 'REFUND_SUCCESS', label: 'Refund successful' },
  ];

  const refundRrnFind = paymentSummary?.refundSummaries?.find((rs: any) => rs?.refundRrn);

  const getLogDetails = (status: any) => {
    const log = refundLogs?.find((log: any) => log?.status === status);
    return log
      ? { icon: <DynamicIcon status={log?.status} />, date: displayDateTime(log?.createdAt) }
      : { icon: <DynamicIcon status="PENDING" />, date: '--' };
  };

  const copyToClipboard = () => {
    const copyText = refundRrnFind?.refundRrn;
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(copyText);
      dispatch(setMessage('RRN has been copied to the clipboard.'));
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = copyText;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        dispatch(setMessage('RRN has been copied to the clipboard.'));
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }

      document.body.removeChild(textArea);
    }
  };

  const refundSummary = paymentSummary?.refundSummary;
  const refund = cancellationData?.refund;

  const totalRefundAmount = refundSummary?.totalRefundAmount ?? refund?.totalRefundAmount;
  const refundCoin = refundSummary?.refundCoins ?? refund?.coinsRefundable;

  return (
    <Box sx={{ bgcolor: '#F4F6F5', py: '15px' }}>
      <Typography variant="body2" fontWeight={600} p="12px 20px" color="gray">
        Refund details
      </Typography>
      <Container sx={{ mb: '10px' }}>
        <Card>
          {totalRefundAmount > 0 && <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" onClick={toggleRefundBreakupDrawer}>
              <Typography variant="h2">{formatToINR(totalRefundAmount)}</Typography>
              <ChevronRightIcon sx={{ color: 'text.secondary' }} />
            </Box>
            <Typography variant="body3" color="gray">
              Refunded amount{refundCoin > 0 ? ' (incl. Niyo coins)' : ''}
            </Typography>
            <Divider sx={{ border: '1px solid #F4F6F5', my: '12px' }} />
          </Box>
          }
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body3" color="gray">
              Paid via{' '}
              {paymentSummary?.paymentMode && formatPaymentMethod(paymentSummary?.paymentMode)}
            </Typography>
            <Typography variant="body3" color="gray">
              {paymentSummary?.payedAt &&
                getFormatedDate(paymentSummary?.payedAt, 'EEE, dd MMM, yyyy HH:MM')}
            </Typography>
          </Box>
          <Divider sx={{ border: '1px solid #F4F6F5', my: '12px' }} />
          <Box>
            <Stepper activeStep={0} orientation="vertical">
              {cancellationRequestLog?.length > 0 && (
                <Step key={0}>
                  {' '}
                  <StepLabel
                    StepIconProps={{ style: { color: 'green', fontSize: '12px' } }}
                    icon={<StatusCircle color="#49A27A" />}
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Cancellation Initiated</Typography>
                      <Typography variant="caption" color="gray">
                        {displayDateTime(cancellationRequestLog?.[0]?.createdAt)}
                      </Typography>
                    </Box>
                  </StepLabel>
                </Step>
              )}
              {paymentSuccessfulLog?.length > 0 && !cancellationRequestLog?.length && (
                <Step key={0}>
                  {' '}
                  <StepLabel
                    StepIconProps={{ style: { color: 'green', fontSize: '12px' } }}
                    icon={<StatusCircle color="#49A27A" />}
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Payment Successful</Typography>
                      <Typography variant="caption" color="gray">
                        {displayDateTime(paymentSuccessfulLog?.[0]?.createdAt)}
                      </Typography>
                    </Box>
                  </StepLabel>
                </Step>
              )}
              {bookingFailedLog?.length > 0 && (
                <Step key={0}>
                  {' '}
                  <StepLabel
                    StepIconProps={{ style: { color: 'green', fontSize: '12px' } }}
                    icon={<StatusCircle color="#C01100" />}
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Booking Failed</Typography>
                      <Typography variant="caption" color="gray">
                        {displayDateTime(bookingFailedLog?.[0]?.createdAt)}
                      </Typography>
                    </Box>
                  </StepLabel>
                </Step>
              )}
              {refundSteps?.map(({ key, label }) => {
                const { icon, date } = getLogDetails(key);
                return (
                  <Step key={key}>
                    <StepLabel icon={icon}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="body2">{label}</Typography>
                        <Typography variant="caption" color="gray">
                          {date}
                        </Typography>
                      </Box>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {refundRrnFind?.refundRrn && (
              <Box p="10px 15px" mt="20px" bgcolor="#F4F6F5" borderRadius="8px" display="flex">
                <Box mr="10px" mt="2px">
                  <OAImage
                    src="info-icon.svg"
                    folder="icons"
                    alt="info"
                    style={{ minWidth: '16px' }}
                  />
                </Box>
                <Box sx={{ maxWidth: '100%' }}>
                  <Typography component="p" variant="body3" mb="10px">
                    If there’s any issue, you might want to check with your bank’s customer care.
                  </Typography>
                  <Typography component="p" variant="body3" color="text.secondary">
                    Your RRN is:
                  </Typography>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      bgcolor: 'yellow.light',
                      borderRadius: '5px',
                      pl: '10px',
                      maxWidth: '100%',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {refundRrnFind?.refundRrn}
                    </Typography>
                    <Box display="flex">
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ border: '0.5px solid #5A6068', ml: '15px' }}
                      />
                      <IconButton
                        edge={false}
                        aria-label="back"
                        sx={{
                          minWidth: '36px',
                        }}
                        onClick={copyToClipboard}
                      >
                        <OAImage src="copy-secondary.svg" folder="icons" alt="copy" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          {isRefundFailed && (
            <Box p="10px 15px" mt="20px" bgcolor="#F4F6F5" borderRadius="8px" display="flex">
              <Box mr="10px" mt="2px">
                <OAImage src="info-icon.svg" folder="icons" alt="info" />
              </Box>
              <Box>
                <Typography variant="body3">
                  Your refund is taking time but rest assured, we're on top of it. Reach out to our
                  support team for any further assistance.
                </Typography>
              </Box>
            </Box>
          )}
        </Card>
      </Container>
      <OASwipeableDrawer
        title={<Typography fontWeight={600}>Refund Summary</Typography>}
        open={isRefundBreakupOpen}
        onOpen={toggleRefundBreakupDrawer}
        onClose={toggleRefundBreakupDrawer}
      >
        <RefundBreakup data={data} cancellationData={cancellationData} />
      </OASwipeableDrawer>
    </Box>
  );
};

export default RefundDetails;

import { Box, Card, CircularProgress, Typography } from "@mui/material"
import { OAButton, OAImage } from "../../components"
import { formatToINR } from "../../utils"

const CfarSection = ({
  cfarInsuranceData,
  isCfarAdded,
  handleUpdateAddOnServices,
  isLoadingAddOn
}: any) => {
  return (
    <Box px='20px' my='30px' bgcolor='#E6EEFF' py='20px'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <Typography color='#13387E' fontWeight={600}>Get Cancellation Protection</Typography>
          <Typography variant='body2' color='text.secondary' mt='5px'>Get a full refund on your flight for any cancellation reason.</Typography>
        </Box>
        <Box>
          <OAImage
            src='tata-aig-insur.svg'
            folder='icons'
            alt='insur'
            sx={{ width: '32px', height: '32px', maxWidth: '32px' }}
          />
        </Box>
      </Box>
      <Box mt='25px'>
        <Box position='relative'>
          <Box
            sx={{ bgcolor: '#13387E', p: '1px 8px', position: 'absolute', top: '-12px', left: '25px' }}
            borderRadius='24px'
            width='fit-content'
          >
            <Typography color='#E6EEFF' variant='body3'>No conditions</Typography>
          </Box>
          <Card sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '15px'
          }}>
            <Typography fontWeight={600}>{formatToINR(cfarInsuranceData?.totalAmount ?? cfarInsuranceData?.additionalInfo?.perTravellerInsurancePricing)}<Typography variant='body3' fontWeight={600}>/ traveller</Typography></Typography>
            <OAButton
              onClick={handleUpdateAddOnServices}
              size="small"
              disabled={isLoadingAddOn}
              sx={{
                color: isCfarAdded?.totalAmount ? '#14191F' : '#fff',
                backgroundColor: isLoadingAddOn
                  ? '#E0E0E0'
                  : isCfarAdded?.totalAmount
                    ? '#FFE5E2'
                    : '#0A1017',
                '&&:hover': {
                  backgroundColor: isLoadingAddOn
                    ? '#E0E0E0'
                    : isCfarAdded?.totalAmount
                      ? '#FFE5E2'
                      : '#0A1017',
                },
                '&&:focus': {
                  backgroundColor: isLoadingAddOn
                    ? '#E0E0E0'
                    : isCfarAdded?.totalAmount
                      ? '#FFE5E2'
                      : '#0A1017',
                },
                display: 'flex',
                width: 'fit-content',
                minWidth: '75px',
              }}
            >
              {
                <Box display="flex" alignItems="center">
                  {isLoadingAddOn ? (
                    <CircularProgress size={20} sx={{ color: '#14191F' }} />
                  ) : (
                    <>
                      {!isCfarAdded?.totalAmount && (
                        <OAImage src="add-white.svg" folder="icons" alt="add" />
                      )}
                      <Typography
                        variant="body3"
                        sx={{
                          color: isCfarAdded?.totalAmount ? '#14191F' : '#fff',
                          ml: '5px',
                        }}
                      >
                        {isCfarAdded?.totalAmount ? 'Remove' : 'Add'}
                      </Typography>
                    </>
                  )}
                </Box>
              }
            </OAButton>
          </Card>
          <Box mt='10px'>
            <Typography variant='body3' color='text.secondary' mt='10px'>Be stress-free and receive a full refund without any question asked. T&C apply.</Typography>
          </Box>
        </Box></Box>
    </Box>
  )
}

export default CfarSection;
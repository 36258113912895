import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface UserInputStateProps {
  data: any;
  selectedFlight: any;
  loading: boolean;
  error: any;
  sort: any;
  filter: any;
  flightPrices: any;
}
/**
 * Default state object with initial values.
 */
const initialState: UserInputStateProps = {
  data: {},
  selectedFlight: {},
  loading: false,
  error: '',
  sort: 'plh',
  filter: null,
  flightPrices: [],
};

/**
 * Create a slice as a reducer containing actions.
 */
export const searchFlightSlice = createSlice({
  name: 'searchFlight',
  initialState,
  reducers: {
    setSort: (
      state: Draft<typeof initialState>,
      action: PayloadAction<typeof initialState.sort>
    ) => {
      state.sort = action.payload;
    },
    setFilter: (
      state: Draft<typeof initialState>,
      action: PayloadAction<typeof initialState.filter>
    ) => {
      state.filter = action.payload;
    },
    setFlights: (
      state: Draft<typeof initialState>,
      action: PayloadAction<typeof initialState.filter>
    ) => {
      state.data = action.payload;
    },
    setSelectedFlight: (state, action) => {
      state.selectedFlight = action.payload;
    },
    clearSelectedFlight: (state) => {
      state.selectedFlight = {};
    },
    setFlightPrices: (state, action) => {
      state.flightPrices = action.payload;
    },
  },
});

// A small helper of user state for `useSelector` function.
export const getSearchFlightState = (state: { flightList: any }) => state.flightList;
export const getSortState = (state: { flightList: any }) => state.flightList.sort;
export const getFilterState = (state: { flightList: any }) => state.flightList.filter;

export const { setSort, setFilter, setFlights, setSelectedFlight, clearSelectedFlight, setFlightPrices } =
  searchFlightSlice.actions;

export default searchFlightSlice?.reducer;

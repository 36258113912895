import styled from '@emotion/styled';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { OAImage } from './OAImage';

interface OASliderProps {
  min?: number | undefined;
  max?: number | undefined;
  value: number;
  onChange: (event: Event, value: number | number[], activeThumb: number) => void;
}

interface SliderThumbProps extends React.HTMLAttributes<unknown> { }

function SliderThumbComponent(props: SliderThumbProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <SliderThumbIcon />
    </SliderThumb>
  );
}

const OACustomSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    width: 24,
    height: 24,
    background: 'transparent',
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
    '& .MuiSliderThumbIcon': {
      width: '100%',
      height: '100%',
      color: '#fff',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    '&.Mui-active': {
      background: 'rgba(0, 167, 111, 0.16)',
      boxShadow: '0 0 2px 10px rgba(0, 167, 111, 0.16)',
    },
    '&:hover': {
      background: 'rgba(0, 167, 111, 0.16)',
      boxShadow: '0 0 2px 6px rgba(0, 167, 111, 0.16)',
    },
    '&.Mui-focusVisible': {
      background: '#00A76F',
      boxShadow: '0 0 2px 6px rgba(0, 167, 111, 0.16)',
    },
  },

  '& .MuiSlider-rail': {
    color: '#d8d8d8',
    opacity: 1,
    height: 10,
  },
  '& .MuiSlider-track': {
      height: 10,
    },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: '#14191F',
    padding: '4px 8px',
    borderRadius: '4px',
    backgroundColor: '#14191F',
    color: '#fff',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: -4,
      left: '50%',
      transform: 'translateX(-50%) rotate(45deg)',
      width: 8,
      height: 8,
      backgroundColor: '#14191F',
    },
  },
}));

const OASlider = ({ value, onChange, min, max }: OASliderProps) => {

  const formatDuration = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}m`;
  };

  return (
    <OACustomSlider
      value={value}
      onChange={onChange}
      valueLabelDisplay="auto"
      min={min}
      step={30}
      max={max}
      slots={{
        thumb: SliderThumbComponent,
      }}
      valueLabelFormat={(value) => formatDuration(value)}
    />
  )
}

export default OASlider;

const SliderThumbIcon = () => (
  <OAImage src={'sliderTiny.png'} folder='icons' height={24} width={24} alt="slider-thumb" />
)

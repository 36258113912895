import { useEffect, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import FlightCard from './FlightCard';
import { IFlight } from '../../type';
import { OAEmpty, OAImage } from '../../components';

const ReturnsFlightList = ({
  flightOptions,
  selectedFlight,
  tripType,
  isInternational,
  onSelect,
  handleClearFilter,
}: {
  flightOptions: any;
  selectedFlight: any;
  tripType: 'R' | 'O';
  isInternational: boolean;
  onSelect: (obj: IFlight, type: 'onward' | 'return') => void;
  handleClearFilter: () => void;
}) => {
  const cardRefsOnward: any = useRef({});
  const cardRefsReturn: any = useRef({});
  const gridRefOnward = useRef(null);
  const gridRefReturn = useRef(null);

  // Reset styles of all cards
  const resetCardStyles = (cards: any) => {
    Object.values(cards).forEach((card: any) => {
      if (card) {
        // card.style.borderColor = '#DEE2E1';
        card.style.background = '#FDFDFD';
      }
    });
  };

  // Set style for the selected card
  const setCardStyle = (card: any) => {
    if (card) {
      card.style.background = '#D9FCF3';
      // card.style.borderColor = '#00C6A8';
    }
  };

  // Delay the onSelect action
  const delaySelect = (flight: any, type: any) => {
    setTimeout(() => {
      onSelect(flight, type);
    }, 200);
  };

  const handleClick = (flight: any, type: any) => {
    const cardRefs = type === 'onward' ? cardRefsOnward.current : cardRefsReturn.current;
    resetCardStyles(cardRefs);
    setCardStyle(cardRefs[flight.id]);
    delaySelect(flight, type);
  };

  useEffect(() => {
    // reset all cards first
    resetCardStyles(cardRefsOnward.current);
    resetCardStyles(cardRefsReturn.current);

    const { onwardFlightOptions = [], returnFlightOptions = [] } = flightOptions ?? {};
    
    // highlight selected flights from selectedFlight prop
    if (selectedFlight?.onward?.id && cardRefsOnward.current[selectedFlight?.onward?.id]) {
      setCardStyle(cardRefsOnward.current[selectedFlight.onward.id]);
    }
    
    if (selectedFlight?.return?.id && cardRefsReturn.current[selectedFlight?.return?.id]) {
      setCardStyle(cardRefsReturn.current[selectedFlight?.return?.id]);
    }
    
    // if no selection exists, highlight first cards
    if (!selectedFlight?.onward?.id && onwardFlightOptions?.[0]) {
      setCardStyle(cardRefsOnward.current[onwardFlightOptions?.[0]?.id]);
    }
    
    if (!selectedFlight?.return?.id && returnFlightOptions?.[0]) {
      setCardStyle(cardRefsReturn.current[returnFlightOptions?.[0]?.id]);
    }
  }, [flightOptions, selectedFlight]); // added selectedFlight to dependencies

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        ref={gridRefOnward}
        sx={{
          overflowY: 'auto',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': { display: 'none' },
          height: 'calc(100vh - 262px)',
        }}
      >
        {flightOptions?.onwardFlightOptions?.length > 0 ? (
          flightOptions?.onwardFlightOptions?.map((obj: IFlight, index: number) => (
            <Box
              ref={(el) => (cardRefsOnward.current[obj.id] = el)}
              key={obj.id}
              mb={flightOptions?.onwardFlightOptions?.length - 1 === index ? '90px' : 0}
              sx={{
                border: '0.5px solid #DEE2E1',
                bgcolor: '#FDFDFD',
              }}
            >
              {obj?.price?.exclusiveFare && 
              <Box p='10px 10px 0 10px' mb='-4px'>
                <OAImage src={'niyo-exclusive-secondary.svg'} folder='offer' alt="exc" />
                </Box>
              }
              <FlightCard
                data={obj}
                onClick={() => handleClick(obj, 'onward')}
                tripType={tripType}
                isInternational={isInternational}
              />
            </Box>
          ))
        ) : (
          <OAEmpty
            illustration={<OAImage src="person-with-phone.svg" alt="empty" />}
            bodytext="No flights are available for this route"
            buttonText="Clear Filter"
            onClickBtn={handleClearFilter}
            customButtonHeight
            buttonWidth="110px"
          />
        )}
      </Grid>
      <Grid
        item
        xs={6}
        ref={gridRefReturn}
        sx={{
          overflowY: 'auto',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': { display: 'none' },
          height: 'calc(100vh - 262px)',
        }}
      >
        {flightOptions?.returnFlightOptions?.length > 0 ? (
          flightOptions?.returnFlightOptions?.map((obj: IFlight, index: number) => (
            <Box
              ref={(el) => (cardRefsReturn.current[obj.id] = el)}
              key={obj.id}
              mb={flightOptions?.returnFlightOptions?.length - 1 === index ? '90px' : 0}
              sx={{
                border: '0.5px solid #DEE2E1',
                bgcolor: '#FDFDFD',
              }}
            >
              {obj?.price?.exclusiveFare && 
              <Box p='10px 10px 0 10px' mb='-4px'>
                <OAImage src={'niyo-exclusive-secondary.svg'} folder='offer' alt="exc" />
                </Box>
              }
              <FlightCard
                data={obj}
                onClick={() => handleClick(obj, 'return')}
                tripType={tripType}
                isInternational={isInternational}
              />
            </Box>
          ))
        ) : (
          <OAEmpty
            illustration={<OAImage src="person-with-phone.svg" alt="empty" />}
            bodytext="No flights are available for this route"
            buttonText="Clear Filter"
            onClickBtn={handleClearFilter}
            customButtonHeight
            buttonWidth="110px"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ReturnsFlightList;

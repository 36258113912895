import { Box, Typography } from "@mui/material";
import { OACheckbox } from "../../../components";

const FilterCheckboxItem = ({
  label,
  value,
  checked,
  onChange,
  price = null,
  time = null
}: {
  label: React.ReactNode,
  value: string,
  checked: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  price?: number | null
  time?: string | null
}) => (
  <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
    <Box 
      display='flex' 
      alignItems='center' 
      sx={{ 
        minWidth: 0,
        flex: 1
      }}
    >
      <Typography 
        variant='body2' 
        noWrap
        sx={{ 
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {label}
      </Typography>
    </Box>
    <Box display='flex' alignItems='center' gap='4px' flexShrink={0}>
      {price && <Typography variant='body3' fontWeight={600} color='text.secondary'>₹{price.toLocaleString()}</Typography>}
      {time && (
        <Typography variant='body3' fontWeight={600} color='text.secondary' ml={1}>
          {time}
        </Typography>
      )}
      <OACheckbox
        value={value}
        checked={checked}
        onChange={onChange}
        sx={{ width: 'revert' }}
      />
    </Box>
  </Box>
);

export default FilterCheckboxItem;
import { createSlice } from '@reduxjs/toolkit';

// Initial state with default flags
const initialState = {
  burnCoinsFeat: true,
  fareCalendarFeat: true,
  smbFeat: true,
  srpBottomDrawerFeat: false,
  noConvenienceFeeBanner: true,
  esimOffer: true,
  loungeBanner: false,
  studentFareFeat: true,
  premiumUserFeat: false,
  convenienceFeeTag: false,
  offersBenefitsFeat: false,
  cfarFeat: false
};

export const featuresSlice = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    setFeatureFlag: (state, action) => {},
    setBurnCoinsFeat: (state, action) => {
      state.burnCoinsFeat = true;
    },
    setSmbFeat: (state, action) => {
      state.smbFeat = action.payload;
    },
    setNoConvenienceFeeBanner: (state, action) => {
      state.noConvenienceFeeBanner = action.payload;
    },
    setEsimOffer: (state, action) => {
      state.esimOffer = action.payload;
    },
    setLoungeBanner: (state, action) => {
      state.loungeBanner = action.payload;
    },
    setStudentFareFeat: (state, action) => {
      state.studentFareFeat = action.payload;
    },
    setPremiumUserFeat: (state, action) => {
      state.premiumUserFeat = action.payload;
    },
    setConvenienceFeeTag: (state, action) => {
      state.convenienceFeeTag = action.payload;
    },
    setOffersBenefitsFeat: (state, action) => {
      state.offersBenefitsFeat = action.payload;
    },
    setCfarFeat: (state, action) => {
      state.cfarFeat = action.payload;
    }
  },
});

export const {
  setFeatureFlag,
  setBurnCoinsFeat,
  setSmbFeat,
  setNoConvenienceFeeBanner,
  setEsimOffer,
  setLoungeBanner,
  setStudentFareFeat,
  setPremiumUserFeat,
  setConvenienceFeeTag,
  setOffersBenefitsFeat,
  setCfarFeat
} = featuresSlice.actions;

export const getFeatureFlag = (state: { features: any }) => state.features;

export default featuresSlice.reducer;

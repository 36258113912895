import { useEffect, useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from '../../store';
import { setTravelDetail } from '../../store/slices/userInputSlice';

import { eventsTracker } from '../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { OAImage } from '../../components';

interface FlightSearchItemProps {
  item: {
    from?: { iata: string; city: string };
    to?: { iata: string; city: string };
    tripStart?: { date: Date };
    tripEnd?: { date: Date };
    travellerCount?: {
      adult?: number;
      child?: number;
      infant?: number;
    };
    cabinType?: string;
    isReturn?: boolean;
    tripType?: string;
    international?: boolean;
    fareGroup?: string;
  };
  onDelete: () => void;
}

const RecentFlightSearchItem = ({ item, onDelete }: FlightSearchItemProps) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleRecentSearch = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    const totalTravelers =
      (item?.travellerCount?.adult || 0) +
      (item?.travellerCount?.child || 0) +
      (item?.travellerCount?.infant || 0);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Flight',
        ctaAction: 'RecSearch',
        screenDuration: totalScreenDuration?.toString(),
        otherData: {
          from: item?.from?.iata,
          to: item?.to?.iata,
          departureDate: item?.tripStart?.date,
          class: item?.cabinType,
          numberOfTravellers: totalTravelers,
          destination: item?.international ? 'international' : 'domestic',
        },
      },
      posthog
    );

    // here in item, we ain't getting tripType so we are setting it here
    dispatch(
      setTravelDetail({
        ...item,
        tripType: item?.tripEnd?.date ? 'R' : 'O',
      })
    );

    const { travellerCount = {}, tripStart, tripEnd, cabinType, fareGroup } = item;
    const { adult = 0, child = 0, infant = 0 } = travellerCount;

    const tripType = tripEnd?.date ? 'R' : 'O';
    const traveller = `adults=${adult}&childs=${child}&infants=${infant}`;

    const fromTo =
      `${item?.from?.iata}` +
      `&fromCity=${item?.from?.city}` +
      `&to=${item?.to?.iata}` +
      `&toCity=${item?.to?.city}` +
      `&fromDate=${item?.tripStart?.date}` +
      `${item?.tripEnd?.date ? `&toDate=${item?.tripEnd?.date}` : ''}` +
      `${item?.fareGroup ? `&fareGroup=${item?.fareGroup}` : ''}`;

    navigate(`results?tripType=${tripType}&cabin=${cabinType}&${traveller}&from=${fromTo}`);
  };

  const formatPassengers = (travellerCount: any) => {
    const { adult = 0, child = 0, infant = 0 } = travellerCount || {};
    const parts = [];
    if (adult > 0) parts.push(`${adult} ${adult === 1 ? 'Adult' : 'Adults'}`);
    if (child > 0) parts.push(`${child} ${child === 1 ? 'Child' : 'Children'}`);
    if (infant > 0) parts.push(`${infant} ${infant === 1 ? 'Infant' : 'Infants'}`);
    return parts.join(', ') || '1 Adult'; // Default to 1 Adult if no passengers
  };

  const totalPassengers = formatPassengers(item?.travellerCount);

  return (
    <Card
      sx={{
        p: '12px 15px',
        mr: '15px',
        cursor: 'pointer',
        width: '210px',
      }}
      onClick={handleRecentSearch}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" fontWeight={600}>{item?.from?.iata}</Typography>

          <OAImage
            src={item?.tripEnd?.date ? 'two-way-arrow.svg' : 'one-way-arrow.svg'}
            folder="icons"
            alt="arrow"
            sx={{ mx: '10px', }}
          />

          <Typography variant="body1" fontWeight={600}>{item?.to?.iata}</Typography>
        </Box>
        <Box  onClick={(e) => {
            e.stopPropagation(); // This prevents the card click event from firing
            onDelete();
          }}>
         <OAImage src="close.svg" folder="icons" alt="close" />
        </Box>
      </Box>
      <Box>
        <Typography variant='body3' color='text.secondary'>{totalPassengers}</Typography>
      </Box>
      <Box display="flex" alignItems="center" gap="4px" mr="15px" mt='15px'>
        <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }} fontWeight={600}>
          {item?.tripStart?.date ? format(item?.tripStart?.date, 'd MMM') : ''}
          {item?.tripEnd?.date ? ` - ${format(item?.tripEnd?.date, 'd MMM')}` : ''}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>&bull;</Typography>
        <Typography variant='body3' color='text.secondary' sx={{ whiteSpace: 'nowrap' }}>{item?.tripEnd?.date ? 'Round Trip' : 'One Way'}</Typography>
      </Box>
    </Card>
  );
};

export default RecentFlightSearchItem;

import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react';
import { v4 as uuidv4 } from 'uuid';
import { store } from '../store';
import { setToken } from '../store/slices/authSlice';
import { eventLogError } from '../utils';
import PostHog from 'posthog-js';
import logError from '../utils/errorLogger';
import { Mutex } from 'async-mutex';
import { jwtDecode } from 'jwt-decode';

const mutex = new Mutex();
let tokenExpirationTime: number | null = null;

const baseQuery = fetchBaseQuery({
  baseUrl: new URL(process.env.REACT_APP_API_URL!).toString(),
  prepareHeaders: (headers, { getState }) => {
    const state: any = getState();

    const token = state?.auth?.token;
    const lat = state?.userInfo?.lat || 12.9716;
    const long = state?.userInfo?.long || 77.5946;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    headers.set('lat', lat);
    headers.set('log', long);

    if (state?.userInfo?.isMock) headers.set('mock', 'true');

    // only for dev purposes
    if (process.env.REACT_APP_TEST_TOKEN) {
      headers.set('organizationcode', 'NIYOGLOBAL');
      headers.set('organizationid', '9f5c0166-3957-4c54-8fe6-212bc12026ec');
    }

    const uuid = uuidv4();
    headers.set('x-correlation-id', `oa-flight-pwa-${uuid}`);

    const minimalState = {
      lastScreen: state.global.lastScreen,
      paymentInProgress: state.global.paymentInProgress,
    };
    const stateJson = JSON.stringify(minimalState);
    const encodedState = encodeURIComponent(stateJson);

    headers.set('global-state', encodedState);
    return headers;
  },
});

const createErrorMessage = (response: any, id: string) => {
  const statusCode = response?.status;
  const mainMessage =
    response?.data && response?.data?.message ? response?.data?.message : 'No message available';
  const errorMessage =
    response?.data && response?.data?.errors && response?.data?.errors?.length > 0
      ? response?.data?.errors?.[0]?.message
      : 'No error details';

  // Concatenate the required string with comma separation
  return `${id}, ${statusCode}, ${mainMessage}, ${errorMessage}`;
};

const baseQueryWithRetry = retry(baseQuery, {
  maxRetries: 3,
});

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  await mutex.acquire();
  try {
    const state: any = api.getState();
    let token = state?.auth?.token;

    const refreshTokenIfNeeded = async () => {
      if (
        window.flutter_inappwebview &&
        window.niyo_refresh_token &&
        typeof window.niyo_refresh_token === 'function'
      ) {
        console.log('Refreshing token');
        const refreshResult = await window.niyo_refresh_token();
        console.log('refreshResult --->>>>>', refreshResult);
        if (refreshResult) {
          store.dispatch(setToken(refreshResult));
          token = refreshResult;
          tokenExpirationTime = null; // resetting expiration time for the new token
        }
      }
    };

    if (token) {
      if (!tokenExpirationTime) {
        const decodedToken = jwtDecode(token);
        tokenExpirationTime = decodedToken.exp! * 1000; // converting to milliseconds
      }

      const currentTime = Date.now();
      const timeUntilExpiration = tokenExpirationTime - currentTime;

      // if token is expired or about to expire in less than 10 seconds, refreshing it
      if (timeUntilExpiration < 10000) {
        await refreshTokenIfNeeded();
      }
    } else {
      // if there's no token, trying to refresh
      await refreshTokenIfNeeded();
    }

    let result = await baseQuery(args, api, extraOptions);
    if (result?.error) {
      let apiEndpoint = api?.endpoint ?? '';
      let capitalized = apiEndpoint.charAt(0).toUpperCase() + apiEndpoint.slice(1);
      logError(result?.error, {
        source: capitalized,
        endpoint: api?.endpoint,
      });
      const state: any = api.getState();
      const posthogEventData = {
        flowName: 'Flight',
        screenName: 'Error',
        ctaAction: capitalized,
        screenDuration: '1',
        otherData: {
          errorSeen: window.location.href,
          userInfo: state?.auth?.user,
          request: args,
          error: result?.error,
        },
      };
      eventLogError(
        {
          screen: api?.endpoint,
          error: createErrorMessage(result?.error, state?.auth?.user?.id),
        },
        posthogEventData,
        PostHog
      );
    }

    // Check for a specific condition to determine if re-authorization is needed (e.g., 401 status code)
    if (result.error && result.error.status === 401) {
      console.log('401->result', result);
      await refreshTokenIfNeeded();

      if (token) {
        const newArgs =
          typeof args === 'string'
            ? args
            : {
                ...args,
                headers: {
                  ...args.headers,
                  Authorization: `Bearer ${token}`,
                },
              };

        // Retry the query with the new token
        result = await baseQuery(newArgs, api, extraOptions);
      }
    }

    return result;
  } finally {
    mutex.release();
  }
};

export const baseApi = baseQueryWithReAuth;

import { useEffect, useState } from 'react';

export const useNearbyAirportMessage = (order: any) => {
  const [nearbyAirportNudges, setNearbyAirportNudges] = useState<any>({ onward: [], return: [] });

  useEffect(() => {
    if (order) {
      const nudges: any = { onward: [], return: [] };
      const searchedFrom = order?.flightSearchInfo?.from?.iata;
      const searchedTo = order?.flightSearchInfo?.to?.iata;

      order?.flights?.forEach((flight: any) => {
        const segments = flight?.segments;
        const firstSegment = segments[0];
        const lastSegment = segments[segments.length - 1];

        const addNudge = (
          type: 'onward' | 'return',
          index: number,
          message: string,
          distance?: string
        ) => {
          nudges[type].push({ index, message, distance });
        };

        if (flight?.departureType === 'ONWARD') {
          if (firstSegment?.departure?.airport?.iata !== searchedFrom) {
            addNudge(
              'onward',
              0,
              `The take-off airport is different from the one you searched for.`
            );
          }
          if (lastSegment?.arrival?.airport?.iata !== searchedTo) {
            addNudge(
              'onward',
              segments.length - 1,
              `The landing airport is different from the one you searched for.`
            );
          }
        } else if (flight.departureType === 'RETURN') {
          if (firstSegment?.departure?.airport?.iata !== searchedTo) {
            addNudge(
              'return',
              0,
              `The take-off airport is different from the one you searched for.`
            );
          }
          if (lastSegment?.arrival?.airport?.iata !== searchedFrom) {
            addNudge(
              'return',
              segments.length - 1,
              `The landing airport is different from the one you searched for.`
            );
          }
        }
      });

      setNearbyAirportNudges(nudges);
    }
  }, [order]);

  return nearbyAirportNudges;
};

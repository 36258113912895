import { Box, Skeleton, Typography } from '@mui/material';
import RecentFlightSearchItem from './RecentFlightSearchItem';
import { useAirportDataQuery } from '../../services/airportDataApi';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../../store';
import { getAuthInfo } from '../../store/slices/authSlice';
import { setMessage } from '../../store/slices/snackbarSlice';

const layoutProps = {
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  overflowY: 'hidden',
  minWidth: '100%', // Adjust based on your layout
  scrollbarWidth: 'none' /* For Firefox */,
  '&::-webkit-scrollbar': {
    display: 'none' /* For Chrome, Safari and Edge */,
  },
  // Add more styling as needed to match your layout
};

const RecentFlightSearch = () => {
  const { user } = useSelector(getAuthInfo);
  const dispatch = useDispatch();
  const { data, isLoading, refetch } = useAirportDataQuery({}, { skip: !user });
  const [removedItems, setRemovedItems] = useState<Set<string>>(new Set());

  //triggering refetch on component mount
  useEffect(() => {
    if (user) refetch();
  }, [refetch, user]);

  const handleRemoveSearch = (searchId: string) => {
    setRemovedItems(prev => new Set([...prev, searchId]));
    dispatch(setMessage('Recent search removed'));
  };

  const filteredSearches = data?.data?.recentSearches?.filter(
    (item: any) => !removedItems.has(item.id)
  );

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton sx={{ fontSize: '1rem', width: '100px' }} />
          <Box sx={layoutProps}>
            {[...Array(3)]?.map((_, index) => (
              <Skeleton key={index} height="110px" width="160px" sx={{ marginRight: 2 }} />
            ))}
          </Box>
        </>
      ) : (
        filteredSearches?.length > 0 && (
          <>
            <Typography variant="body1" sx={{ fontWeight: 600, mb: '20px', ml: '20px' }}>
              Recent Searches
            </Typography>
            <Box sx={layoutProps}>
              {filteredSearches?.map((item: any, index: number) => (
                <Box key={index} sx={{ ml: index === 0 ? '20px' : '' }}>
                  <RecentFlightSearchItem key={item?.id} item={item} onDelete={() => handleRemoveSearch(item?.id)} />
                </Box>
              ))}
            </Box>
          </>
        )
      )}
    </>
  );
};

export default RecentFlightSearch;

import { FC, useState } from 'react';
import { Box, Card, Container, Divider, IconButton, Skeleton, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { OAFareSummary, OAImage, OASwipeableDrawer } from '../../../components';
import { formatPaymentMethod, formatToINR } from '../../../utils';
import { useDispatch, useSelector } from '../../../store';
import { getFeatureFlag } from '../../../store/slices/featuresSlice';
import { getUserInfo } from '../../../store/slices/userInfoSlice';
import { format } from 'date-fns';
import { setMessage } from '../../../store/slices/snackbarSlice';

interface PaymentAndInvoiceProps {
  order: any;
  isLoading: boolean;
}

const PaymentAndInvoice: FC<PaymentAndInvoiceProps> = ({ order, isLoading }) => {
  const dispatch = useDispatch();
  const { esimOffer } = useSelector(getFeatureFlag);
  const orderAmount = order?.orderAmount;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleDrawer = () => setIsOpen((prev) => !prev);
  const { tags } = useSelector(getUserInfo);
  const addOnServices = orderAmount?.addOnServicesAmount?.addonServiceBreakUps?.find(
    (item: any) => item?.type === 'NIYO_PREMIUM_PLAN' || item?.type === 'NIYO_GOLD_PREMIUM_USER'
  );

  // Check if `order.metaTags` is an array before using `includes`
  const isOrderPurchasedAsAPremiumUser =
    Array.isArray(order?.metaTags) && order?.metaTags?.includes('NIYO_PREMIUM_PLAN');

  // Check if `tags` is an array before using `includes`
  const isPremiumUser =
    Array.isArray(tags) &&
    (tags?.includes('NIYO_GOLD_PREMIUM_USER') || tags?.includes('NIYO_SILVER_PREMIUM_USER'));

  const copyToClipboard = () => {
    const copyText = orderAmount?.paymentSummary?.transactionId;
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(copyText);
      dispatch(setMessage('Transaction ID has been copied to the clipboard.'));
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = copyText;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        dispatch(setMessage('Transaction ID has been copied to the clipboard.'));
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }

      document.body.removeChild(textArea);
    }
  };

  return (
    <>
      <Container>
        <Typography variant="body1" sx={{ fontWeight: 600, mb: '16px' }}>
          Payment details
        </Typography>
        {orderAmount?.paymentSummary?.amountInCoins > 0 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              bgcolor: 'primary.light',
              p: '6px 10px',
              borderRadius: '4px',
              mt: '8px',
              mb: '15px',
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="body3" sx={{ fontWeight: 600, mr: '4px' }}>
                {formatToINR(orderAmount?.paymentSummary?.amountInCoins)}
              </Typography>
              <Typography variant="body3">saved on this trip</Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <Typography variant="body3" sx={{ mr: '4px' }}>
                using
              </Typography>
              <Typography variant="body3" sx={{ fontWeight: 600 }}>
                {formatToINR(orderAmount?.paymentSummary?.coinsRedeemed, false)}
              </Typography>
              <OAImage
                src="3xNiyo_coin.png"
                alt="coins"
                sx={{ mx: '5px', height: '18px', width: '18px' }}
              />
            </Box>
          </Box>
        ) : null}
        <Card sx={{ p: '15px', mb: '12px' }}>
          <Typography variant="body2" color="text.secondary">
            Amount Paid
          </Typography>

          <Typography variant="h2" mt="5px" mb="10px">
            {orderAmount?.paymentSummary?.amountInCash ? (
              formatToINR(orderAmount?.paymentSummary?.amountInCash)
            ) : isLoading ? (
              <Skeleton variant="text" />
            ) : (
              '0'
            )}
          </Typography>
          {orderAmount?.paymentSummary?.payedAt && (
            <Typography component="p" variant="body2" mb="15px">
              Paid via {formatPaymentMethod(orderAmount?.paymentSummary?.paymentMode ?? '')} on{' '}
              {format(orderAmount?.paymentSummary.payedAt, 'do MMM yyyy • h:mm a')}
            </Typography>
          )}

          {orderAmount?.paymentSummary?.transactionId && (
            <Box>
              <Typography component="p" variant="body3" color="text.secondary">
                Transaction ID:
              </Typography>

              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  bgcolor: 'yellow.light',
                  borderRadius: '5px',
                  pl: '10px',
                  maxWidth: '100%',
                  boxSizing: 'border-box',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {orderAmount?.paymentSummary?.transactionId}
                </Typography>
                <Box display="flex">
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ border: '0.5px solid #5A6068', ml: '15px' }}
                  />
                  <IconButton
                    edge={false}
                    aria-label="back"
                    sx={{
                      minWidth: '36px',
                    }}
                    onClick={copyToClipboard}
                  >
                    <OAImage src="copy-secondary.svg" folder="icons" alt="copy" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          )}
        </Card>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py="8px"
          onClick={toggleDrawer}
        >
          <Typography variant="body2">View payment details</Typography>
          <ChevronRightIcon fontSize="small" sx={{ color: 'secondary.main' }} />
        </Box>
        {/* <Box display="flex" alignItems="center" justifyContent="space-between" py="8px">
          <Typography variant="body2">Download invoice</Typography>
          <ChevronRightIcon fontSize="small" sx={{ color: 'secondary.main', fontWeight: 300 }} />
        </Box> */}
      </Container>
      {order?.international && esimOffer && (
        <Box px="16px" mt="20px">
          <OAImage src="esim.svg" folder="offer" alt="esim" width="100%" />
        </Box>
      )}
      <Divider sx={{ borderBottomWidth: '4px', borderColor: '#F4F6F5', my: '20px' }} />
      <OASwipeableDrawer
        title="Payment details"
        open={isOpen}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
        isContainer={false}
      >
        <OAFareSummary
          data={order}
          isPremiumUser={addOnServices || isOrderPurchasedAsAPremiumUser || isPremiumUser}
          premiumPaidDetails={addOnServices}
        />
      </OASwipeableDrawer>
    </>
  );
};

export default PaymentAndInvoice;

import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { OAHeader, OASwitch } from '../../components';
import { HeaderActionFlight } from './FlightHeaderAction';
import { useDispatch, useSelector } from '../../store';
import RecentFlightSearch from './RecentFlightSearch';
import { getUserInfo, setLatLong, setMock } from '../../store/slices/userInfoSlice';
import SearchParameters from './SearchParameters';
import { isTestEmailForProd, parseObject } from '../../utils';
import { getAuthInfo } from '../../store/slices/authSlice';
import { useGetBookingsListQuery } from '../../services/bookingsApi';
import CoinsBanner from './CoinsBanner';
import { setIsCoinsApplied } from '../../store/slices/userInputSlice';
import OffersBenefits from './OffersBenefits';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { getOffersState } from '../../store/slices/offersSlice';
import SpecialOffers from './SpecialOffers';

const VERSION = '4.4.33';
const Flight = () => {
  const dispatch = useDispatch();
  const { token, user, campaigns } = useSelector(getAuthInfo);
  const { data } = useGetBookingsListQuery({}, { skip: !user });
  const { isMock } = useSelector(getUserInfo);
  const { coins } = useSelector(getUserInfo);
  const [scrollTop, setScrollTop] = useState<boolean>(true);
  const { burnCoinsFeat, premiumUserFeat } = useSelector(getFeatureFlag);
  const { data: offers } = useSelector(getOffersState);
  const isBannerDisplayed = useMemo(
    () => burnCoinsFeat || campaigns?.eligibleCampaigns?.length > 0,
    [burnCoinsFeat, campaigns]
  );

  useEffect(() => {
    if (coins > 9) {
      dispatch(setIsCoinsApplied(true));
    }
  }, [coins]);

  useEffect(() => {
    if (
      window.flutter_inappwebview &&
      window.niyo_location &&
      typeof window.niyo_location === 'function'
    ) {
      window.niyo_location().then((res) => {
        const resParse = parseObject(res);
        dispatch(setLatLong(resParse));
      });
    }
    console.log(`Version ${VERSION}`);
  }, []);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setMock(event.target.checked));
    },
    [dispatch]
  );

  const activeBookingsCount = useMemo(() => {
    if (!data) return 0;
    const today = new Date();
    today?.setHours(0, 0, 0, 0);
    return data?.filter(
      (booking: any) =>
        ['BOOKING_CONFIRMED', 'UPCOMING', 'BOOKING_IN_PROGRESS', 'PROCESSING_OFFLINE']?.includes(
          booking?.bookingStatus
        ) && new Date(booking?.tripStartDate) >= today
    )?.length;
  }, [data]);

  const bgImgUrl = 'https://oa-flight-assets.s3.ap-south-1.amazonaws.com/offer/top-container.png';

  return (
    <Box
      sx={{
        overflow: 'auto',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <OAHeader
        title={''}
        appBarSx={{
          borderBottom: 'none',
          backgroundImage: `url(${bgImgUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: premiumUserFeat ? '100%' : 'cover',
          backgroundPosition: premiumUserFeat ? 'none' : 'center',
          minHeight: premiumUserFeat
            ? window?.innerHeight === 667 && window?.innerWidth === 375
              ? '26vh'
              : '30vh'
            : window?.innerHeight === 667 && window?.innerWidth === 375
            ? '205px'
            : '300px',
          position: 'relative', // Added position relative
          width: '100%',
        }}
        action={<HeaderActionFlight totalActiveBookings={activeBookingsCount} />}
        backgroundImageText={
          <CoinsBanner
            title={premiumUserFeat ? 'Flights' : 'Book flights with'}
            subTitle={
              premiumUserFeat ? 'Redeem Niyo coins with additional discount' : '₹0 convenience fee'
            }
            titleSx={{
              color: '#FFFFFF',
              fontWeight: premiumUserFeat ? 600 : 400,
              fontSize: premiumUserFeat ? '30px' : '25px',
              lineHeight: premiumUserFeat ? '40px' : '35px',
            }}
            subTitleSx={{
              color: premiumUserFeat ? '#FDFDFD' : '#FFFFFF',
              mt: '10px',
              fontWeight: premiumUserFeat ? 400 : 600,
              fontSize: premiumUserFeat ? '14px' : '30px',
              lineHeight: premiumUserFeat ? '20px' : '40px',
            }}
          />
        }
      />

      <Container
        sx={{
          borderRadius: '20px 20px 0px 0px',
          position: 'relative',
          mt: '-35px', // Pull the container up to overlap the header
          backgroundColor: 'white', // Ensure that the container background is white to overlay
        }}
      >
        <SearchParameters shouldFetchLocations={true} setScrollTop={setScrollTop} />
        {/* <Box mt="30px">
          <CoinsBanner />
        </Box> */}
      </Container>
      <SpecialOffers />
      {isBannerDisplayed && <OffersBenefits />}
      {/* <Container sx={{ py: '30px' }}>
        <OAImage folder="icons" src="WhyUsBanner.svg" alt="whyUs" width="100%" />
      </Container> */}
      <Box
        sx={{
          mb: '30px',
          mt: isBannerDisplayed ? 0 : '30px',
        }}
      >
        <RecentFlightSearch />
      </Box>

      {isTestEmailForProd(user?.email) && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            textAlign: 'center',
            mt: '24px',
            p: '10px',
            color: 'grey.800',
            bgcolor: 'grey.500',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
          }}
        >
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Version {VERSION}
          </Typography>

          <Box onClick={() => navigator.clipboard.writeText(token)} ml="24px">
            (Token)
          </Box>

          <Box ml="24px">
            <OASwitch onChange={handleOnChange} checked={isMock} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Flight;

import { FC, useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import Sort from './Sort';
import Filter from './Filter';
import { OAChip, OALoadingOverlay } from '../../../components';
import { getFilterState, getSearchFlightState } from '../../../store/slices/searchFlightSlice';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { eventsTracker } from '../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';

interface FilterAndSortProps {
  filterData: any;
  flightsCount: any;
  countOfStops: any;
  countOfAirports: any;
  countOfDeparture: any;
  countOfReturnDeparture: any;
  currentSortTab: number;
  setFilterData: (values: any) => void;
  onClickSort: (value: string) => void;
  onClickClearSort: () => void;
  onClickFilter: (filterData: any) => void;
  onClickClearFilter: () => void;
  setCurrentSortTab: (value: number) => void;
}
const FilterAndSort: FC<FilterAndSortProps> = ({
  filterData,
  flightsCount,
  countOfStops,
  countOfAirports,
  countOfDeparture,
  countOfReturnDeparture,
  currentSortTab,
  setFilterData,
  onClickSort,
  onClickClearSort,
  onClickFilter,
  onClickClearFilter,
  setCurrentSortTab,
}) => {
  const { data } = useSelector(getSearchFlightState);
  const filterValue = useSelector(getFilterState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());
  const posthog = usePostHog();

  const toggleNonStop = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    const isNonStopSelected = filterValue?.stops?.includes('0');
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SRP',
        ctaAction: isNonStopSelected ? 'NonStopClear' : 'NonStopOnly',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    setIsLoading(true);
    const updatedFilter = { ...filterValue };
    const hasStops = Array.isArray(updatedFilter.stops);
    const hasReturnStops = Array?.isArray(updatedFilter?.returnStops);
    const includesNonStop = hasStops && updatedFilter.stops.includes('0');
    const includesReturnNonStop = hasReturnStops && updatedFilter?.returnStops?.includes('0');


    if (includesNonStop) {
      updatedFilter.stops = updatedFilter.stops.filter((stop: any) => stop !== '0');
      if (updatedFilter.stops.length === 0) {
        delete updatedFilter.stops;
      }
    } else {
      updatedFilter.stops = hasStops ? [...updatedFilter.stops, '0'] : ['0'];
    }

    if (includesReturnNonStop) {
      updatedFilter.returnStops = updatedFilter?.returnStops?.filter((stop: any) => stop !== '0');
      if (updatedFilter?.returnStops?.length === 0) {
        delete updatedFilter?.returnStops;
      }
    } else {
      updatedFilter.returnStops = hasReturnStops ? [...updatedFilter.returnStops, '0'] : ['0'];
    }

    isEmpty(updatedFilter) ? onClickClearFilter() : onClickFilter(updatedFilter);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  return (
    <>
      {isLoading ? (
        <OALoadingOverlay />
      ) : (
        <Container sx={{ py: '8px', overflow: 'hidden', px: 0 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflowX: 'auto',
              msOverflowStyle: 'none', // Correct syntax for the vendor-prefixed CSS properties
              scrollbarWidth: 'none', // Firefox
              '&::-webkit-scrollbar': {
                display: 'none', // Chrome, Safari, Opera
              },
            }}
          >
            <Box mr="8px">
              {data && (
                <Sort
                  currentSortTab={currentSortTab}
                  setCurrentSortTab={setCurrentSortTab}
                  onClick={onClickSort}
                  onClickClearSort={onClickClearSort}
                />
              )}
            </Box>
            <Box mr="8px">
              {data && (
                <Filter
                  filterData={filterData}
                  setFilterData={setFilterData}
                  onClick={onClickFilter}
                  onClickClearFilter={onClickClearFilter}
                  filtersCount={flightsCount}
                  countOfStops={countOfStops}
                  countOfAirports={countOfAirports}
                />
              )}
            </Box>
            {data && (
              <OAChip
                label="Non-stop"
                onClick={toggleNonStop}
                selected={filterValue?.stops?.includes('0')}
                disabled={data?.meta && data?.results?.onward?.length > 0 ? false : true}
                sx={{
                  backgroundColor: filterValue?.stops?.includes('0') ? '#D9FCF3 !important' : 'white',
                  borderColor: filterValue?.stops?.includes('0') ? '#00C6A8' : '#DEE2E1',
                }}
              />
            )}
          </Box>
        </Container>
      )}
    </>
  );
};

export default FilterAndSort;

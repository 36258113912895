import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

type Status = 'pending' | 'complete' | 'error' | 'waiting';

interface TimelineItem {
  title: string;
  date?: string;
  status: Status;
}

interface TimelineProps {
  items: TimelineItem[];
}

const TimelineContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(3),
}));

const TimelineItemContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: theme.spacing(3),
  '&:last-child': {
    paddingBottom: 0,
  },
}));

const TimelineLine = styled(Box)<{ status: Status }>(({ theme, status }) => ({
  position: 'absolute',
  left: 7,
  top: 15,
  bottom: 0,
  width: 1,
  backgroundColor: '#DEE2E1',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: -1,
    top: -28,
    bottom: 0,
    width: 3,
    backgroundColor: 'transparent',
  },
}));

const TimelineIcon = styled(Box)<{ status: Status }>(({ theme, status }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: 16,
  height: 16,
  borderRadius: '50%',
  color:
    status === 'complete'
      ? theme.palette.success.main
      : status === 'error'
      ? theme.palette.error.main
      : status === 'pending'
      ? '#F1BF4D'
      : '#8C9097',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const getStatusIcon = (status: Status) => {
  switch (status) {
    case 'pending':
      return <WatchLaterIcon sx={{ fontSize: 14, color: 'inherit' }} />;
    case 'complete':
      return <CheckCircleIcon sx={{ fontSize: 14, color: 'inherit' }} />;
    case 'error':
      return <ErrorIcon sx={{ fontSize: 14, color: 'inherit' }} />;
    default:
      return <AccessTimeIcon sx={{ fontSize: 14, color: 'inherit' }} />;
  }
};

export function Timeline({ items }: TimelineProps) {
  return (
    <TimelineContainer>
      {items.map((item, index) => (
        <TimelineItemContainer key={index}>
          <TimelineIcon status={item.status}>{getStatusIcon(item.status)}</TimelineIcon>
          {index < items.length - 1 && <TimelineLine status={item.status} />}
          <Box sx={{ ml: 3 }}>
            <Typography variant="body2" fontWeight={500} mb="10px">
              {item.title}
            </Typography>
            {item?.date && (
              <Typography variant="body2" color="text.secondary">
                {item.date}
              </Typography>
            )}
          </Box>
        </TimelineItemContainer>
      ))}
    </TimelineContainer>
  );
}

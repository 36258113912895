import { Box, Container } from "@mui/material";
import { useGetOffersQuery } from "../../services/offersApi";
import { useEffect } from "react";
import { setOffersData } from "../../store/slices/offersSlice";
import { useDispatch } from "../../store";

const SpecialOffers = () => {
  const dispatch = useDispatch();
  const { data: offersData, refetch } = useGetOffersQuery({});

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (offersData?.data?.length) {
      dispatch(setOffersData(offersData?.data));
    }
  }, [offersData?.data?.length]);

  return (
    <Box>
      {offersData?.data?.length > 0 && <Container sx={{ my: '30px', px: '0px' }}>
        {offersData?.data?.map((offer: any) => (
          <Box key={offer?.id}>
            <img src={offer?.bannerImage} alt="offer" width="100%" />
          </Box>
        ))}
      </Container>}
    </Box>
  );
};

export default SpecialOffers;
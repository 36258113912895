export const formatPaymentMethod = (inputString: any) => {
  // Check if inputString is empty or undefined
  if (!inputString) {
    return inputString;
  }

  return inputString
    .toLowerCase()
    .replace(/_/g, ' ')
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

import { Box, Card, Typography } from "@mui/material"
import { OAImage } from "../../components"

const ComparedFlights = ({ comparativePrices, travelDetail, layoutProps, handleComparativePriceClick }: any) => {
  return (
    <Box bgcolor={'#E6EAE9'} py='20px' mb='20px'>
      <Box display="flex" alignItems="center" gap="10px" px="16px" mb='15px'>
        <Box>
          <OAImage src="less-price.svg" folder="icons" alt="lower-fares" width="40px" height="40px" />
        </Box>
        <Box>
          <Typography fontWeight="600">Lower fares on other dates</Typography>
          <Typography variant="body3" color="text.secondary">Save more on these flights to {travelDetail?.to?.city}</Typography>
        </Box>
      </Box>
      <Box sx={layoutProps}>
        {comparativePrices?.map((price: any, index: number) => (
          <Card
            key={price?.date}
            sx={{ border: '1px solid #DEE2E1', mr: '15px', ml: index === 0 ? '16px' : '', padding: '12px 12px', flex: '0 0 auto' }}
            onClick={() => handleComparativePriceClick(price?.date)}
          >
            <Typography color="text.secondary" variant="body2">
              {new Date(price?.date).toLocaleDateString('en-US', {
                weekday: 'short',
                day: 'numeric',
                month: 'short'
              })}
            </Typography>
            <Typography color="black" variant="body2" mt="4px">Starting <Typography component="span" fontSize="14px" lineHeight="20px" fontWeight="600">{price?.price}</Typography></Typography>
          </Card>
        ))}
      </Box>
    </Box>
  )
}

export default ComparedFlights;
export interface FilterSection {
  id: string;
  label: string;
  available: boolean;
}

export const getFilterSections = (data: any, isInternationalReturn: boolean): FilterSection[] => [
  { id: 'stops', label: 'Stops', available: data?.meta?.stops?.length > 0 },
  { id: 'flightDuration', label: 'Flight Duration', available: data?.meta?.maxFightDuration?.display },
  { id: 'airlines', label: 'Airlines', available: data?.meta?.airlines?.length > 0 },
  { id: 'onwardDeparture', label: 'Departure', available: Boolean(data?.meta?.onwardDeparture) },
  { id: 'returnDeparture', label: 'Return', available: Boolean(data?.meta?.returnDeparture && !isInternationalReturn) },
  { id: 'originAirports', label: 'Departure Airports', available: data?.meta?.originNearbyAirports?.airports?.length > 0 },
  { id: 'destinationAirports', label: 'Arrival Airports', available: data?.meta?.destinationNearbyAirports?.airports?.length > 0 },
  { id: 'layover', label: 'Layover Airports', available: data?.meta?.layoverAirports?.length > 0 },
  { id: 'layoverDuration', label: 'Layover Duration', available: data?.meta?.maxLayoverDuration?.display },
];
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Card, Divider, Typography } from '@mui/material';
import {
  OAButton,
  OAHeader,
  OAHeaderSubTitle,
  OAHeaderTitle,
  OAImage,
  OAScrollToTop,
  OASwipeableDrawer,
} from '../../../components';
import ContactSupport from './ContactSupport';
import { useGetBookingsListQuery, useGetOrderByIdQuery } from '../../../services/bookingsApi';
import StatusMsgAndBtn from './StatusMsgAndBtn';
import TripDetailsSegments from './TripDetailsSegments';
import TravellerDetails from './TravellerDetails';
import PaymentAndInvoice from './PaymentAndInvoice';
import CancelBooking from './CancelBooking';
import ImportantInfo from './ImportantInfo';
import PrimaryContactDetails from './PrimaryContactDetails';
import RefundDetails from './RefundDetails';
import { bookingStatusLabels } from '../../../constants';
import SomethingWentWrong from '../../Review/SomethingWentWrong';
import AddOnsSummary from '../../../components/OASMB/components/AddOnsSummary';
import { getFeatureFlag } from '../../../store/slices/featuresSlice';
import { useDispatch, useSelector } from '../../../store';
import useIsVisible from '../../../hooks/useIsVisible';
import { formatToINR } from '../../../utils';
import { setTags } from '../../../store/slices/userInfoSlice';

const BookingStatus = () => {
  const { orderId } = useParams();

  const {
    data,
    isLoading,
    isSuccess: isOrderSuccess,
    isError: isErrorOrderById,
    refetch: refetchOrderById,
  } = useGetOrderByIdQuery(orderId);
  const { smbFeat } = useSelector(getFeatureFlag);
  const dispatch = useDispatch();
  const { refetch: refetchBookingsList } = useGetBookingsListQuery({});
  const [isUnsuccessfulBooking, setIsUnsuccessfulBooking] = useState<boolean>(false);
  const [unsuccessfulBookingText, setUnsuccessfulBookingText] = useState<any>('');

  const pageRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(pageRef);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const flight = data?.data?.flights?.[0];
  const segments = flight?.segments ?? [];
  const location = useLocation();
  const importantInfo = segments?.[0]?.baggageInfo?.baggages?.[0]?.info;
  const discountedAmount = data?.data?.orderAmount?.discountTotalAmount?.totalDiscountAmount;
  const isCardDiscountAvailable = data?.data?.orderAmount?.discountTotalAmount?.discountBreakUps?.some(
    (discount: any) => discount?.type === 'CARD_FLAT_DISCOUNT'
  );

  const headerInfo = {
    from: { city: data?.data?.flightSearchInfo?.from?.city },
    to: { city: data?.data?.flightSearchInfo?.to?.city },
    tripType: data?.data?.tripType,
  };

  const headerSecondaryInfo = {
    travellerCount: data?.data?.travellerCount,
    tripStart: data?.data?.tripStart,
    tripEnd: data?.data?.tripEnd,
    cabinType:
      data?.data?.flights?.[0]?.ticketNumberInfo?.[0]?.cabinType?.toLowerCase() ??
      data?.data?.flightSearchInfo?.cabinType,
    tripType: data?.data?.tripType,
  };

  useEffect(() => {
    if (isErrorOrderById) setIsApiError(true);
  }, [isErrorOrderById]);

  useEffect(() => {
    if (isOrderSuccess) {
      refetchBookingsList();
    }
  }, [isOrderSuccess, refetchBookingsList]);

  const toggleApiError = () => setIsApiError(true);

  const eventFreshChat = () => {
    if (
      window.flutter_inappwebview &&
      window.niyo_fresh_chat &&
      typeof window.niyo_fresh_chat === 'function'
    ) {
      let appSubStage = `${data?.data?.bookingStatus?.toLowerCase()}`;
      const status = bookingStatusLabels?.find(
        (obj: any) => data?.data?.bookingStatus === obj?.value
      )?.label;
      if (status) {
        appSubStage = status?.toLowerCase();
      }
      const freshChatData = {
        appTag: 'oa_onarrival_mytrips',
        appSpoke: 'onarrival',
        appStage: 'onarrival_mytrips',
        appSubStage: `onarrival_mytrips_${appSubStage}`,
        faqTag: ['oa_onarrival_mytrips'],
      };
      const freshChatDataStringify = JSON.stringify(freshChatData);
      window.niyo_fresh_chat(freshChatDataStringify);
    } else {
      console.log('only allowed in mobile');
    }
  };

  const onwardFlights = data?.data?.flights?.find(
    (flight: any) => flight?.departureType === 'ONWARD'
  );
  const returnFlights = data?.data?.flights?.find(
    (flight: any) => flight?.departureType === 'RETURN'
  );

  const segmentBasedAddOns = () => {};

  useEffect(() => {
    if (data?.data && data?.data?.orderAmount?.addOnServicesAmount) {
      dispatch(setTags((prevTags: any) => {
        const updatedTags = Array.isArray(prevTags) ? [...prevTags] : [];
        if (!updatedTags.includes("NIYO_GOLD_PREMIUM_USER")) {
          updatedTags.push("NIYO_GOLD_PREMIUM_USER");
        }
        return updatedTags;
      }));
    }
  }, [data?.data]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (isVisible && data?.data?.bookingStatus === 'BOOKING_IN_PROGRESS') {
      intervalId = setInterval(() => {
        refetchOrderById();
        refetchBookingsList();
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isVisible, data?.data?.bookingStatus]);

  const bookingFailedText = () => (
    <Box>
      <Typography variant="h6" lineHeight="25px" mb="10px" fontWeight="600">
        Your booking has failed
      </Typography>
      <Typography>Don't worry — any deducted amount will be refunded within 7 days.</Typography>
      <Typography mt="16px">
        Please don't hesitate to reach out to our customer support team for any queries.
      </Typography>
    </Box>
  );

  const paymentFailedText = () => (
    <Box>
      <Typography variant="h6" lineHeight="25px" mb="10px" fontWeight="600">
        Your payment has failed
      </Typography>
      <Typography>
        Your payment for the{' '}
        {data?.data?.flightSearchInfo?.tripType === 'ONWARD' ? 'oneway' : 'return'} flight booking
        from {data?.data?.flightSearchInfo?.from?.city} to {data?.data?.flightSearchInfo?.to?.city}{' '}
        has failed
      </Typography>
    </Box>
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const review = searchParams.get('review');
    if (review === '' && data?.data?.bookingStatus === 'BOOKING_IN_PROGRESS') {
    } else if (review === '' && data?.data?.bookingStatus === 'PAYMENT_FAILED') {
      setIsUnsuccessfulBooking(true);
      setUnsuccessfulBookingText(paymentFailedText);
    } else if (review === '' && data?.data?.bookingStatus === 'BOOKING_FAILED') {
      setIsUnsuccessfulBooking(true);
      setUnsuccessfulBookingText('Your booking has failed');
      setUnsuccessfulBookingText(bookingFailedText);
    }
  }, [location, data?.data]);

  const toggleUnsuccessfulBooking = () => {
    setIsUnsuccessfulBooking(!isUnsuccessfulBooking);
  };

  const amountToBeRefunded = data?.data?.orderAmount?.paymentSummary?.refundSummary?.additionalInfo?.strikeOffTotalRefundCharges;

  return (
    <Box ref={pageRef}>
      <OAScrollToTop />
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 100,
          bgcolor: 'background.paper',
        }}
      >
        <OAHeader
          title={<OAHeaderTitle travelDetail={headerInfo} />}
          subTitle={<OAHeaderSubTitle travelDetail={headerSecondaryInfo} />}
          loading={isLoading}
          action={
            <Box onClick={eventFreshChat}>
              <OAImage src="chatbot.svg" folder="icons" alt="chat" />
            </Box>
          }
        />
      </Box>
      <StatusMsgAndBtn order={data?.data} isLoading={isLoading} />
      {amountToBeRefunded && <>
          <Box width="100%" mb='-10px'>
            <OAImage src='wave.svg' folder='icons' alt='wave' width='100%' />
          </Box>
          <Box px='20px' py='10px' bgcolor='#E6EEFF'>
            <Card sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box display='flex' alignItems='center'>
                <Box>
                  <OAImage src='tata-aig-insur.svg' folder='icons' alt='ins' />
                </Box>
                <Box ml='10px'>
                  <Typography variant='body2' fontWeight={600}>You made savings!</Typography>
                  <Typography variant='body3' color='text.secondary'>on cancellation fees</Typography>
                </Box>
              </Box>
              <Box>
                <Typography color='#49A27A'>{formatToINR(amountToBeRefunded)}</Typography>
              </Box>
            </Card>
          </Box>
          <Box width="100%" bgcolor='#F4F6F5'>
            <OAImage src='bottomwave.svg' folder='icons' alt='wave' width='100%'  />
          </Box>
        </>}
      {(data?.data?.bookingStatus === 'CANCELLED' ||
        data?.data?.bookingStatus === 'BOOKING_FAILED') && <RefundDetails data={data} />}
      <TripDetailsSegments order={data?.data} isLoading={isLoading} />
      <TravellerDetails traveller={data?.data?.travellers} isLoading={isLoading} />
      {data?.data?.selectedJourneyAncillaries?.length > 0 && smbFeat && (
        <>
          {' '}
          <AddOnsSummary order={data?.data} isBookingStatusPage supplierCode={data?.data?.supplierCode} />
          <Divider sx={{ borderBottomWidth: '4px', borderColor: '#F4F6F5', my: '20px' }} />
        </>
      )}
      <PaymentAndInvoice order={data?.data} isLoading={isLoading} />
      {isCardDiscountAvailable && <Box
          position="relative"
          m='20px'
          borderRadius='15px'
        >
          <OAImage src="festive-flash-bg.svg" folder="offer" alt="thumb" width='100%' />
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            flexDirection="column"
            p="20px"
          >
            <Box display='flex'>
            <Box>
              <OAImage src="delight.svg" width='40px' height='40px' folder="offer" alt="thumb" />
            </Box>
            <Box ml='10px'>
              <Typography variant='body2' fontWeight='600' color='#13387E'>
                You saved extra {formatToINR(discountedAmount)} on this flight booking
              </Typography>
              <Box mt='5px'>
              <Typography  variant='body3' color='#13387E'>
                Festive flash sale discount applied
              </Typography>
              </Box>
            </Box>
            </Box>
          </Box>
        </Box>}
      {![
        'CANCELLED',
        'BOOKING_FAILED',
        'BOOKING_IN_PROGRESS',
        'PROCESSING_OFFLINE',
        'PAYMENT_FAILED',
        'CANCELLATION_REQUESTED',
      ]?.includes(data?.data?.bookingStatus) &&
       <CancelBooking data={data?.data} />}
      <ImportantInfo info={importantInfo} />
      <PrimaryContactDetails contactDetails={data?.data?.contactDetails} />
      <ContactSupport onClick={eventFreshChat} bookingId={data?.data?.bookingId} />
      <OASwipeableDrawer
        title={<OAImage src="error-icon.svg" folder="icons" alt="Erro" />}
        open={isApiError}
        onOpen={toggleApiError}
        onClose={toggleApiError}
        isContainer={false}
        isError={true}
      >
        <SomethingWentWrong />
      </OASwipeableDrawer>
      <OASwipeableDrawer
        title={<OAImage src="error-icon.svg" folder="icons" alt="error" />}
        open={isUnsuccessfulBooking}
        onOpen={toggleUnsuccessfulBooking}
        onClose={toggleUnsuccessfulBooking}
        isError={true}
      >
        <Typography>{unsuccessfulBookingText}</Typography>
        <OAButton
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ my: '30px' }}
          onClick={toggleUnsuccessfulBooking}
        >
          Okay
        </OAButton>
      </OASwipeableDrawer>
    </Box>
  );
};

export default BookingStatus;

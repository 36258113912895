import { useMemo } from 'react';
import { useSelector } from '../store';
import { getSearchFlightState } from '../store/slices/searchFlightSlice';

export const useNearbyAirportDistance = (data: any) => {
  const { selectedFlight } = useSelector(getSearchFlightState);

  const originAirports = useMemo(
    () => selectedFlight?.nearbyAirports?.origin?.airports ?? [],
    [selectedFlight?.nearbyAirports?.origin?.airports]
  );

  const destinationAirports = useMemo(
    () => selectedFlight?.nearbyAirports?.destination?.airports ?? [],
    [selectedFlight?.nearbyAirports?.destination?.airports]
  );

  const mergeArray = useMemo(
    () => [...originAirports, ...destinationAirports],
    [originAirports, destinationAirports]
  );

  const findOrigin = useMemo(() => {
    return mergeArray?.find((airport) => airport?.iata === data?.departure?.airport?.iata);
  }, [mergeArray, data?.departure?.airport?.iata]);

  const findDestination = useMemo(() => {
    return mergeArray?.find((airport) => airport?.iata === data?.arrival?.airport?.iata);
  }, [mergeArray, data?.arrival?.airport?.iata]);

  const display = useMemo(() => {
    if (findOrigin) {
      return {
        message: 'Take-off',
        distance: findOrigin?.distance?.label,
        iata: findOrigin?.distance?.fromIataCode,
      };
    } else if (findDestination) {
      return {
        message: 'Landing',
        distance: findDestination?.distance?.label,
        iata: findDestination?.distance?.fromIataCode,
      };
    }
    return null;
  }, [findOrigin, findDestination]);

  return display;
};

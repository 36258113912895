import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RootState {
  global: any;
  userInput: any;
  flightList: any;
  airportSearch: any;
  snackbar: any;
  userInfo: any;
  offers: OffersState;
}

export interface OffersState {
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: OffersState = {
  data: null,
  loading: false,
  error: null,
};

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setOffersData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    setOffersLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setOffersError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setOffersData, setOffersLoading, setOffersError } = offersSlice.actions;

export const getOffersState = (state: any) => state.offers;

export default offersSlice.reducer; 
export const calculateAmountPayment = (data: any) => {
  // Destructure the values with default fallback
  const {
    amountInCash = 0,
    convenienceAmount = 0,
    discountAmount = 0,
    amountChargeableToCustomer,
  } = data || {};

  // Return amountChargeableToCustomer if it exists, otherwise calculate the amount
  return amountChargeableToCustomer ?? amountInCash + convenienceAmount - discountAmount;
};

import { FC, useEffect, useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { OAImage } from '../OAImage';
import {
  PAYMENT_PROCESSING,
  PAYMENT_PROCESSING_TIMELINE,
  PAYMENT_SUCCESS,
  PAYMENT_SUCCESS_TIMELINE,
  PAYMENT_TIMEOUT,
  PAYMENT_TIMEOUT_TIMELINE,
} from '../../constants';
import { Timeline } from './Timeline';
import { useGetPaymentStatusNiyoMutation } from '../../services/paymentApi';
import { calculateAmountPayment, formatToINR } from '../../utils';

interface PaymentInfoProps {
  isTimeout: boolean;
  flightData: any;
  paymentId: any;
  setIsCancellable: any;
}

const PaymentInfo: FC<PaymentInfoProps> = ({
  isTimeout,
  flightData,
  paymentId,
  setIsCancellable,
}) => {
  const [getPaymentStatusNiyo] = useGetPaymentStatusNiyoMutation();
  const { flightSearchInfo: fs } = flightData || {};

  const [payment, setPayment] = useState<any>(null);
  console.log('fs', fs);

  useEffect(() => {
    let intervalId: any;
    let timeoutId: any;

    if (flightData?.id) {
      const duration = 8 * 60 * 1000; // 8 minutes in milliseconds
      const intervalDuration = 5000; // Call the API every 5 seconds after delay
      const startTime = Date.now(); // Track when we started the duration

      // Function to fetch payment status
      const fetchPaymentStatus = () => {
        getPaymentStatusNiyo(paymentId).then((res) => {
          console.log('res', res?.data?.data);
          const resData = res?.data?.data;
          if (resData) {
            setPayment(resData);
            if (resData?.status === 'SUCCESS') {
              setIsCancellable(false);
              clearInterval(intervalId);
            }
          }
        });
      };

      // Delay the start of the interval by 20 seconds
      timeoutId = setTimeout(() => {
        // Call the API initially
        fetchPaymentStatus();

        // Set up an interval to fetch the status repeatedly
        intervalId = setInterval(() => {
          if (Date.now() - startTime >= duration) {
            clearInterval(intervalId); // Stop the interval after 8 minutes
          } else {
            fetchPaymentStatus();
          }
        }, intervalDuration);
      }, 20000); // 20 seconds delay

      // Cleanup on unmount or dependency change
      return () => {
        clearTimeout(timeoutId);
        clearInterval(intervalId);
      };
    }
  }, []);

  const svgIcon = isTimeout
    ? PAYMENT_TIMEOUT
    : payment?.status === 'SUCCESS'
    ? PAYMENT_SUCCESS
    : PAYMENT_PROCESSING;

  const timeLine = isTimeout
    ? PAYMENT_TIMEOUT_TIMELINE
    : payment?.status === 'SUCCESS'
    ? PAYMENT_SUCCESS_TIMELINE
    : PAYMENT_PROCESSING_TIMELINE;
  const total = Object.values(fs?.travellerCount).reduce((sum: any, value) => sum + value, 0);
  return (
    <>
      <Box textAlign="center">
        <OAImage src={svgIcon?.icon} folder="icons" alt="error" />
        <Typography variant="h2" mt="20px">
          {svgIcon?.title}
        </Typography>
        <Typography variant="body3" color="text.secondary">
          {fs?.from?.iata} - {fs?.to?.iata} • {fs?.isReturn ? 'Return' : 'Oneway'} • {total}{' '}
          travellers
        </Typography>
        {payment && (
          <>
            <Typography component="p" variant="body3" color="text.secondary" mt="25px">
              Total amount
            </Typography>
            <Typography variant="h2" sx={{ fontSize: '30px', lineHeight: '40px' }}>
              {formatToINR(calculateAmountPayment(payment))}
            </Typography>
          </>
        )}
      </Box>
      <Box mx="20px" mt="30px" mb="35px">
        <Card
          sx={{
            display: 'flex',
            bgcolor: svgIcon?.bgColor,
            alignItems: 'flex-start',
            border: 0,
            p: '10px 15px 10px 10px',
          }}
        >
          <OAImage
            src={svgIcon?.icon}
            folder="icons"
            alt="error"
            sx={{ width: '16px', mt: '3px' }}
          />
          {/* <Typography variant="body3" ml="10px">
            {isTimeout || payment?.status === 'SUCCESS'
              ? svgIcon?.text
              : ['PHONEPE', 'PAYTM', 'GOOGLEPAY', 'UPI'].includes(payment?.paymentMode)
              ? 'We are currently checking the status of your payment. Open your UPI app on your smartphone. Complete the payment by entering your UPI PIN.'
              : svgIcon?.text}
          </Typography> */}
          <Typography variant="body3" ml="10px">
            {['PHONEPE', 'PAYTM', 'GOOGLEPAY', 'UPI'].includes(payment?.paymentMode)
              ? payment?.status === 'SUCCESS'
                ? svgIcon?.text
                : 'We are currently checking the status of your payment. Open your UPI app on your smartphone. Complete the payment by entering your UPI PIN.'
              : svgIcon?.text}
          </Typography>
        </Card>
      </Box>
      <Box alignSelf="flex-start">
        <Typography variant="body2" color="text.secondary" ml="20px" mb="15px">
          Status
        </Typography>
        <Timeline items={timeLine} />
      </Box>
    </>
  );
};

export default PaymentInfo;

import { FC, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { OASwipeableDrawer } from '../basic/OASwipeableDrawer';
import { OAButton } from '../basic/OAButton';
import { OACountdown } from '../OACountdown';
import { useCancelTransactionApiMutation } from '../../services/bookingsApi';
import { eventsTracker } from '../../utils/ctEventsTracking';
import posthog from 'posthog-js';
import { OAImage } from '../OAImage';
import PaymentInfo from './PaymentInfo';

interface OALoadingPaymentStatusProps {
  setIsPaymentLoading: any;
  setIsRetryPayment: any;
  transactionId: string;
  timeOutInSeconds: number;
  flightData?: any;
  paymentId: string;
}
export const OALoadingPaymentStatus: FC<OALoadingPaymentStatusProps> = ({
  setIsPaymentLoading,
  setIsRetryPayment,
  transactionId,
  timeOutInSeconds,
  flightData,
  paymentId,
}) => {
  const [cancelTransactionApi] = useCancelTransactionApiMutation();
  const [cancelTransaction, setCancelTransaction] = useState<boolean>(false);
  const [isTimeout, setIsTimeout] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());
  const [isCancellable, setIsCancellable] = useState<boolean>(true);

  const toggleDrawer = () => setCancelTransaction((prev) => !prev);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const onComplete = () => {
    setIsTimeout(true);
  };

  const cancelTransactionHandle = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Loader',
        ctaAction: 'ConfirmCancel',
        screenDuration: totalScreenDuration?.toString(),
        UserAction: {
          userAction: 'User has cancelled the transaction',
        },
      },
      posthog
    );
    cancelTransactionApi(transactionId).then((res: any) => {
      setCancelTransaction(false);
      setIsPaymentLoading(false);
      setIsRetryPayment(true);
      if (
        window.flutter_inappwebview &&
        window.niyo_cancel_current_transaction &&
        typeof window.niyo_cancel_current_transaction === 'function'
      ) {
        window.niyo_cancel_current_transaction();
      }
    });
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: '#FFFFFF',
          zIndex: 9999,
        }}
      >
        {isCancellable && (
          <Box
            sx={{
              position: 'absolute',
              top: 40,
              left: 20,
            }}
            onClick={isTimeout ? cancelTransactionHandle : toggleDrawer}
          >
            <OAImage src="close.svg" folder="icons" alt="close" />
          </Box>
        )}

        <PaymentInfo
          isTimeout={isTimeout}
          paymentId={paymentId}
          flightData={flightData}
          setIsCancellable={setIsCancellable}
        />

        {!isTimeout && (
          <>
            <Box
              sx={{
                p: '20px 40px',
                position: 'fixed',
                bottom: 0,
                left: 0,
                textAlign: 'center',
              }}
            >
              {isCancellable && (
                <Typography variant="body2" sx={{ fontWeight: 600, mb: '8px', color: '#C01100' }}>
                  <OACountdown duration={timeOutInSeconds} onComplete={onComplete} />
                </Typography>
              )}

              <Typography
                variant="body3"
                sx={{
                  color: 'text.secondary',
                }}
              >
                Please do not click refresh or go back until the itinerary page is displayed.
              </Typography>
            </Box>
          </>
        )}

        <OASwipeableDrawer
          sx={{ zIndex: 999999 }}
          title={<OAImage src="error-icon.svg" folder="icons" alt="error" />}
          open={cancelTransaction}
          onOpen={toggleDrawer}
          onClose={toggleDrawer}
          isContainer={false}
        >
          <Container sx={{ pb: '24px' }}>
            <>
              <Typography variant="h6" mb="10px">
                Cancel transaction?
              </Typography>
              <Typography>
                You are midway through a transaction. Your flight booking will get cancelled
              </Typography>
              <OAButton
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: '24px' }}
                onClick={cancelTransactionHandle}
              >
                Confirm
              </OAButton>
            </>
          </Container>
        </OASwipeableDrawer>
      </Box>
    </>
  );
};
